import React from "react";
import { Button, Image, Responsive } from "semantic-ui-react";
import { Link } from "gatsby";

import style from "./style.module.css";

const CtaButton = ({ cta, ctaLink, style }) => {
  if (!cta) {
    return null;
  }

  return (
    <Button primary size="large" href={ctaLink} style={style}>
      {cta}
    </Button>
  );
};

const LearnMoreButton = ({ linkTo }) => {
  if (!linkTo) {
    return null;
  }

  return (
    <Button
      as={Link}
      to={linkTo}
      size="large"
      className={style.learnMoreButton}
    >
      Learn More
    </Button>
  );
}

const Panel = ({
  h1,
  h2,
  h3,
  cta,
  ctaLink,
  img,
  linkTo,
  flip,
  textColor,
  ctaColor,
  ctaTextColor,
  backgroundColor,
  backgroundImagePosition,
  headingColor,
  headingLeft,
  headingTop,
  headingRotate,
  inverted,
  // This is gross, but will get us through until the new website
  // one of the new Homepage Banner Images from Chaos is a different format to the rest
  extraPaddingTop, 
  id // this is for quick-links
}) => {
  return (
    <div
      style={{
        backgroundImage: `url(${img})`,
        backgroundColor: backgroundColor,
        backgroundPosition: backgroundImagePosition,
      }}
      className={`${style.homepagePanel} ${
        flip ? style.homepagePanelFlipped : ""} 
        `}
    >
      
      {/* Anchor link for jumping down the page from age range buttons */}
      <span className={style.quickLinkAnchor} id={id} />
      
      <Image src={img} className={style.mobileImage} />
      <div
        className={`${style.panelContent} ${
          flip ? style.panelContentFlip : ""
        }`}
      >
        <h1
          className={style.mainHeading}
          style={{
            transform: `rotate(${headingRotate}deg)`,
            left: headingLeft,
            top: headingTop,
            color: headingColor
          }}
        >
          {h1}
        </h1>
        {/* 
        Larger Computer
        This is a horrible solution to map these Respsonsive components in react code to the same rules in the CSS breakpoints
        I'm only putting up with this as the site is due to be replaced
        Also FYI, the 'Responsive' component has been deprecated, so isn't in the docs any more, but
        can be found in older source code https://github.com/Semantic-Org/Semantic-UI-React/blob/v0.88.1/src/addons/Responsive/Responsive.js 
        */}
        <Responsive minWidth={1200}>
          <h2
            className={style.subHeading}
            style={{
              color: textColor,
              marginBottom: "0px",
              marginTop: `${extraPaddingTop ?  "35px" : "audo"}`
            }}
          >
            {h2}
          </h2>
          <p
            className={style.descriptor}
            style={{
              color: textColor,
              marginBottom: "20px"
            }}
          >
            {h3}
          </p>
        </Responsive>

        {
        /* Smaller Computer 
        This is a horrible solution to map these Respsonsive components in react code to the same rules in the CSS breakpoints
        I'm only putting up with this as the site is due to be replaced
        Also FYI, the 'Responsive' component has been deprecated, so isn't in the docs any more, but
        can be found in older source code https://github.com/Semantic-Org/Semantic-UI-React/blob/v0.88.1/src/addons/Responsive/Responsive.js 
        */
        }
        <Responsive minWidth={992} maxWidth={1199}>
          <h2
            className={style.subHeading}
            style={{
              color: textColor,
              marginBottom: "0px",
            }}
          >
            {h2}
          </h2>
          <p
            className={style.descriptor}
            style={{
              color: textColor,
              marginBottom: "20px"
            }}
          >
            {h3}
          </p>
        </Responsive>

        {/* Tablet */}
        <Responsive {...Responsive.onlyTablet}>
          <h2
            className={style.subHeading}
            style={{
              color: textColor,
              marginBottom: "0px"
            }}
          >
            {h2}
          </h2>
          <p
            className={style.descriptor}
            style={{
              color: textColor,
              marginBottom: "20px"
            }}
          >
            {h3}
          </p>
        </Responsive>

        {/* Mobile */}
        <Responsive {...Responsive.onlyMobile}>
          <h2
            className={style.subHeading}
            style={{
              color: textColor,
              marginBottom: "0px"
            }}
          >
            {h2}
          </h2>
          <span
            className={style.descriptor}
            style={{
              color: textColor
            }}
          >
            {h3}
          </span>
        </Responsive> 

        <div className={style.actions}>
          <LearnMoreButton linkTo={linkTo} />
          <CtaButton
            cta={cta}
            ctaLink={ctaLink}
            style={{ backgroundColor: ctaColor, color: ctaTextColor }}
          />
        </div>
      </div>
    </div>
  );
};

export default Panel;
