import React from "react";

import LayoutHome from "../components/layout/layout-home";
import SEO from "../components/seo";
import { Link } from "gatsby";

import HomepagePanel from "../components/homepage-panel/homepage-panel";
import { Container, Button } from "semantic-ui-react";
import colors from "../components/colors";
import styles from "./index.module.css";

const IndexPage = () => (
  <LayoutHome>
    <SEO
        title="Rocksteady Music School – Fun and inclusive in-school rock and pop band lessons for primary school children"
        keywords={[`rocksteady`]}
        description="Rocksteady Music School provides in-school fun and inclusive rock and pop band lessons for primary school children."
    />
    <Container>
      <div className={styles.ageButtonContainer}>
        
          <Button as={Link} to="/#mini-rockers" 
            className={styles.ageButton}
            style={{
              backgroundColor: `${colors.miniRockers.home.bg}`
            }}
          >
            MINI ROCKERS
            <span>Reception to Year 2</span>
          </Button>

          <Button as={Link} to="/#rock-heroes" 
            className={styles.ageButton}
            style={{
              backgroundColor: `${colors.rockHeroes.home.bg}`
            }}
          >
            ROCK HEROES
            <span>Year 3 and 4</span>
          </Button>

          <Button as={Link} to="/#rock-icons" 
            className={styles.ageButton}
            style={{
              backgroundColor: `${colors.rockIcons.home.bg}`
            }}
          >
            ROCK ICONS
            <span>Year 5 and 6</span>
          </Button>        
      </div>

      <HomepagePanel
        h1="Rock Icons"
        h2="For Years 5 and 6"
        h3={"Fun and inclusive in-school rock and pop band lessons, for Years 5 and 6. No experience or instrument required."}
        cta="Sign up"
        img="/images/homepage/homepage_header_rock_icons.png"
        linkTo="/info-for-parents/"
        ctaLink="https://booking.rocksteadymusicschool.com"
        textColor={colors.rockIcons.home.text}
        ctaColor={colors.rockIcons.home.cta}
        ctaTextColor={colors.rockIcons.home.ctaText}
        backgroundColor={colors.rockIcons.home.bg}
        headingColor={colors.rockIcons.home.heading}
        headingLeft="-30px"
        headingTop="05px"
        headingRotate="-7"
        backgroundImagePosition="top left"
        id="rock-icons"
      />
      <HomepagePanel
        h1="Rock Heroes"
        h2="For Years 3 and 4"
        h3={"Fun and inclusive in-school rock and pop band lessons, for Years 3 and 4. No experience or instrument required."}
        cta="Sign up"
        img="/images/homepage/homepage_header_rock_heroes.png"
        linkTo="/info-for-parents/"
        ctaLink="https://booking.rocksteadymusicschool.com"
        textColor={colors.rockHeroes.home.text}
        ctaColor={colors.rockHeroes.home.cta}
        ctaTextColor={colors.rockHeroes.home.ctaText}
        backgroundColor={colors.rockHeroes.home.bg}
        headingColor={colors.rockHeroes.home.heading}
        headingLeft="-30px"
        headingTop="05px"
        headingRotate="-7"
        flip
        backgroundImagePosition="top right"
        id="rock-heroes"
      />
      <HomepagePanel
        h1="Mini Rockers"
        h2="For Reception to Year 2"
        h3={"Fun and inclusive in-school rock and pop band lessons, for Reception, Year 1 and Year 2. No experience or instrument required."}
        cta="Sign up"
        img="/images/homepage/homepage_header_mini_rockers.png"
        linkTo="/info-for-parents/"
        ctaLink="https://booking.rocksteadymusicschool.com"
        textColor={colors.miniRockers.home.text}
        ctaColor={colors.miniRockers.home.cta}
        ctaTextColor={colors.miniRockers.home.ctaText}
        backgroundColor={colors.miniRockers.home.bg}
        headingColor={colors.miniRockers.home.heading}
        headingLeft="-35px"
        headingTop="20px"
        headingRotate="-4"
        backgroundImagePosition="bottom left"
        extraPaddingTop={true}
        id="mini-rockers"
      />
      <HomepagePanel
        h1="Foundation"
        h2="Every Child Deserves Their Moment"
        h3="Free and accessible music experiences for all."
        img="/images/homepage/homepage_foundation.png"
        textColor={colors.foundation.home.text}
        backgroundColor={colors.foundation.home.bg}
        headingColor={colors.foundation.home.heading}
        headingLeft="-35px"
        headingRotate="-4"
        headingTop="20px"
        backgroundImagePosition="top left"

        cta="Learn More"
        ctaLink="/foundation"
        ctaColor={colors.foundation.cta}
        ctaTextColor={colors.foundation.ctaText}
        id="foundation"
      />
    </Container>
  </LayoutHome>
);

export default IndexPage;
