import React from "react";
import PropTypes from "prop-types";

import Layout from "../layout/layout";
import style from "./style.module.css";

const LayoutHome = ({ children }) => (
  <div className={style.homeWrapper}>
    <Layout>{children}</Layout>
  </div>
);

LayoutHome.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutHome;
